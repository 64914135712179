import React, { useEffect } from 'react';

import { Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Card } from '@fifth-gear-tech/common-components/dist/components';
import Logger from '@fgt/common/utils/logger';

import './SpeedfolderOnboarding.scss';
import SubscriptionsState from '../../state/subscriptions';
import ModalState from '../../state/modal';
import SpeedfolderState from '../../state/speedfolder';
import { hasAuth } from '../../utils/auth';
import AuthTabs from '../../components/AuthTabs';
import SpeedfolderSignupButton from '../../components/SpeedfolderSignupButton';
import { sfBannerLight } from '../../assets';

import { SPEEDFOLDER_APP_BASE } from '../../utils/constants';

const logger = new Logger(__filename);
const LoadingMessage = <div><strong>Getting SPEEDfolder ready...</strong></div>;

const subscribeToSpeedfolder = async () => {
    try {
        document.body.style.pointerEvents = 'none'; // disable mouse events so users don't do anything weird
        ModalState.open(LoadingMessage);
        await SpeedfolderState.speedfolderCheckout(); // get everything ready
        window.location.href = SPEEDFOLDER_APP_BASE; // redirect
        ModalState.close(LoadingMessage);
    } catch (err) {
        logger.error(err, 'Error subscribing to SPEEDfolder.'); // log error
        window.location.href = SPEEDFOLDER_APP_BASE; // redirect (SF probably won't work at this point but it's better than nothing)
        throw err;
    }
};

const FifthGearSignupForSpeedfolder = () => (
    <div>
        <h5><strong>Login</strong> or <strong>Enroll Company</strong> to get started with <em>SPEEDfolder</em> for free.</h5>
        <AuthTabs
            colorTheme={Colors.Black}
            signUpSuccess={subscribeToSpeedfolder} // for SignUpForm | another callback because dillon has already built a bunch of stuff without using any async logic 🤦
            onLoginSuccess={subscribeToSpeedfolder} // for SignInForm
            defaultTab={'Login'}
        />
    </div>
);

const Speedfolder = () => {
    const isLoggedIn = hasAuth();

    useEffect(async () => {
        if (isLoggedIn) {
            await SubscriptionsState.fetchSubscriptions();
            await subscribeToSpeedfolder();
            return LoadingMessage;
        } else if (!isLoggedIn) {
            ModalState.open(<FifthGearSignupForSpeedfolder />);
        }
    }, []);


    return (
        <div className='SpeedfolderOnboarding'>
            <Card>
                <div className='ButtonForm'>

                    <img src={sfBannerLight} width={'450rem'} alt='speedfolder-banner' />

                    <div className='SignUpButton'>
                        <SpeedfolderSignupButton className='SpeedfolderButton' />
                    </div>

                    <div className='Subscribed'>
                        <small>Already have SPEEDfolder?<em><a href='/app/speedfolder'><span className='OrangeText'>Click here.</span></a></em></small>
                    </div>

                </div>
            </Card>
        </div>);

};

export default Speedfolder;