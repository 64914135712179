import './Home.scss';

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import Logger from '@fgt/common/utils/logger';
import PropTypes from 'prop-types';

import { STRIPE_SPEEDLABEL_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID, STRIPE_PRINTER_PRODUCT_ID, STRIPE_INK_PRODUCT_ID, STRIPE_STARTER_PRODUCT_ID } from '../../utils/constants';

import { scBannerDark, slBannerDark, sfBannerDark, stBannerDark } from '../../assets';

import { EnterIcon } from '@radix-ui/react-icons';

import { Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType, Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import AuthTabs from '../../components/AuthTabs';

import CartState from '../../state/cart';
import ProductsState from '../../state/products';
import ModalState from '../../state/modal';
import { hasAuth } from '../../utils/auth';

// get product prices
const logger = new Logger(__filename);

const SubscriptionCard = ({ img, learnMoreCallback, signUpCallBack, signInCallBack, productsLoaded, subscriptionName }) => {

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={`SubscriptionCard ${!loaded && 'Hidden'}`}>
            <div className={'Banner'}>
                <img src={img} alt='subscription' onLoad={() => setLoaded(true)} />
                <p className='Actions'>
                    {learnMoreCallback && <button onClick={learnMoreCallback}>Learn More</button>}
                    {(productsLoaded && signUpCallBack) && <button onClick={signUpCallBack}>Get {subscriptionName}</button>}
                    {signInCallBack && <button onClick={signInCallBack}>Home</button>}
                </p>
            </div>
        </div >
    );
};

const Home = observer(() => {
    logger.extra();

    const history = useHistory();
    const [{ products }] = useState(() => ProductsState);

    useEffect(() => {
        CartState.emptyCart();
    }, []);

    const addItemToCart = (productId) => {

        try {

            const product = products.find((p) => p.external_product_id == productId);
            const price = product.external_product.prices.find((p) => p.id == product.external_product?.default_price);

            CartState.addItem({ product, price, qty: 1 });
        } catch (err) {
            history.push('/');
        }
    };

    const FifthGearSignupToCheckout = (callback) => (
        <div>
            <AuthTabs
                colorTheme={Colors.Black}
                signUpSuccess={callback}
                onLoginSuccess={callback}
                defaultTab={'Login'}
            />
        </div>
    );

    const checkAuth = (productList) => {
        logger.extra({ productList });

        const callback = () => {
            for (const p of productList) {
                addItemToCart(p);
            }
            ModalState.close();
            history.push('/cart');
        };

        hasAuth() ? callback() : ModalState.open(FifthGearSignupToCheckout(callback));

    };


    const AddPrinterModal = () => {

        const minimumProductLineup = [STRIPE_SPEEDLABEL_PRODUCT_ID, STRIPE_INK_PRODUCT_ID, STRIPE_STARTER_PRODUCT_ID];

        return (
            <div className='AddPrinterModal'>
                <h3>Will you need a label printer?</h3>
                <br />
                <Button label='Yes' colorTheme={Colors.Green} type={ButtonType.None} onClick={() => checkAuth([...minimumProductLineup, STRIPE_PRINTER_PRODUCT_ID])} />

                <Button label='No' colorTheme={Colors.Red} type={ButtonType.None} onClick={() => checkAuth(minimumProductLineup)} />
            </div>
        );
    };

    const checkout = (productId) => {
        if (productId == STRIPE_SPEEDLABEL_PRODUCT_ID) {
            CartState.emptyCart();
            ModalState.open(<AddPrinterModal />);
        } else {
            CartState.emptyCart();
            checkAuth([productId]);
        }
    };

    const productsLoaded = products.length > 0;

    return (
        <div className={'Home'}>

            <div className='Programs'>
                <SubscriptionCard
                    img={sfBannerDark}
                    learnMoreCallback={() => {
                        window.location = 'https://speedfolder.com';
                    }}
                    signInCallBack={() => {
                        window.location = `${window.origin}/app/speedfolder`;
                    }}
                    signUpCallBack={() => {
                        history.push('/speedfolder-onboarding');
                    }}
                    productsLoaded={true} // this doesn't actually need to wait for the products to load in
                    subscriptionName={'SPEEDfolder'}
                />
                <SubscriptionCard
                    img={scBannerDark}
                    learnMoreCallback={() => {
                        window.location = 'https://www.youtube.com/@speedcad5g';
                    }}
                    productsLoaded={productsLoaded}
                    subscriptionName={'SPEEDcad'}
                />
                <SubscriptionCard
                    img={stBannerDark}
                    learnMoreCallback={() => {
                        window.location = 'https://speedtemplate.com';
                    }}
                    signUpCallBack={() => {
                        checkout(STRIPE_SPEEDTEMPLATE_PRODUCT_ID);
                    }}
                    productsLoaded={productsLoaded}
                    subscriptionName={'SPEEDtemplate'}
                />
                <SubscriptionCard
                    img={slBannerDark}
                    learnMoreCallback={() => {
                        window.location = 'https://speedlabel.net';
                    }}
                    signUpCallBack={() => {
                        checkout(STRIPE_SPEEDLABEL_PRODUCT_ID);
                    }}
                    signInCallBack={() => {
                        window.location = hasAuth() ? `${window.origin}/app/speedlabel` : `${window.origin}/login?redirect=${window.origin}/app/speedlabel`;
                    }}
                    productsLoaded={productsLoaded}
                    subscriptionName={'SPEEDlabel'}
                />

                <div className='DashboardButton'>
                    {/* eslint-disable-next-line */}
                    <h5 onClick={() => history.push('/account')}><EnterIcon color='white' />Account Settings </h5>
                </div>
            </div>


        </div >
    );
});

SubscriptionCard.propTypes = {
    img: PropTypes.string,
    learnMoreCallback: PropTypes.func,
    signUpCallBack: PropTypes.func,
    signInCallBack: PropTypes.func,
    productsLoaded: PropTypes.bool,
    subscriptionName: PropTypes.string
};


export default Home;