import './App.scss';
import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Auth0Provider } from '@auth0/auth0-react';
import AppHeader from '../components/AppHeader';
import GlobalModal from '../components/Modal/Modal';
import { ThemeProvider } from '@material-ui/core/styles';

import Account from '../pages/Account';
import Signup from '../pages/Signup';
import Super from '../pages/Super';
import CheckoutSuccess from '../pages/CheckoutSuccess';
import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import Cart from '../pages/Cart';
import SpeedfolderOnboarding from '../pages/SpeedfolderOnboarding';
import Invite from '../pages/Invite';
import PasswordReset from '../pages/PasswordReset';
import UpdateBilling from '../pages/UpdateBilling';
import AdvertiserOnboarding from '../pages/AdvertiserOnboarding';
import GranQuartzOnboarding from '../pages/GranQuartzOnboarding';

import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_REDIRECT_URI, AUTH0_AUDIENCE, ENVIRONMENT } from '../utils/constants';

import theme from '../theme';

import ProductsState from '../state/products';
import SubscriptionsState from '../state/subscriptions';

import { hasAuth, isSuper } from '../utils/auth';
import { SentimentVeryDissatisfiedSharp } from '@material-ui/icons';

import eula from '../assets/eula.pdf';
import sfAdTos from '../assets/sf-ad-tos.pdf';

const urlPrefix = ENVIRONMENT == 'development' ? 'http://localhost:3000' : `https://${window.location.hostname}`;

const App = observer(() => {

    useEffect(() => {

        ProductsState.fetchProducts();

        if (hasAuth()) {
            SubscriptionsState.fetchSubscriptions();
        }

    }, []);

    const SPEEDfolderJobRedirect = () => {
        const { id } = useParams();
        if (hasAuth()) {
            window.location.replace(`${urlPrefix}/app/speedfolder/?jobId=${id}`);
        } else {
            window.location.replace(`${urlPrefix}/app/speedfolder/`);
        }
        return (<></>);
    };

    const SPEEDlabelLabelsRedirect = () => {
        const { id } = useParams();
        if (hasAuth()) {
            // this will hit the speed label app on the login page which on a login success will redirect to labels with the job id
            window.location.replace(`${urlPrefix}/app/speedlabel/?jobId=${id}`);
        } else {
            window.location.replace(`${urlPrefix}/app/speedlabel/`);
        }
        return (<></>);
    };

    const MarketingHashRedirect = () => {
        const { pathname } = useLocation();
        const [, path] = pathname.split('/app/marketing');
        window.location.replace(`${urlPrefix}/app/marketing/#${path}`);
        return (<></>);
    };

    /* eslint-disable-next-line */
    const ApplicationRedirect = ({ location }) => {
        window.location.replace(location);
        return (<></>);
    };

    const NoMatch = () => {
        const { pathname } = window.location;
        // if the user tried to navigate to an app and doesn't have auth use login redirect
        // (if they have auth and are redirected to an /app/ page but still get a 404 then there's nothing we can do)
        // if we try to redirect if they have it causes an infinite loop
        if (pathname.includes('/app/') && !hasAuth()) {
            const split = pathname.split('/');
            const program = split[split.indexOf('app') + 1];
            // go to login page with app redirect
            window.location.href = `${window.origin}/login?redirect=${window.origin}/app/${program}`;
        }

        return (
            <div className='PageNotFound'>
                <h3>404, page not found.</h3>
                {ENVIRONMENT != 'production' ? <img alt='travolta' src='https://media1.tenor.com/m/lx2WSGRk8bcAAAAC/pulp-fiction-john-travolta.gif' /> : <SentimentVeryDissatisfiedSharp />}
            </div>
        );
    };

    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            redirectUri={AUTH0_REDIRECT_URI}
            audience={AUTH0_AUDIENCE}
        >
            <ThemeProvider theme={theme} >
                <div className="App">
                    <AppHeader />
                    <Switch>

                        <Route path='/index.html'>
                            <Redirect to='/' />
                        </Route>

                        <Route path='/' exact={true} >
                            <Home />
                        </Route>

                        <Route path='/account'>
                            {hasAuth() ? <Account /> : <Redirect to='/login' />}
                        </Route>

                        <Route path='/cart' >
                            <Cart />
                        </Route>

                        <Route path='/checkout-success'>
                            {hasAuth() ? <CheckoutSuccess /> : <Redirect to='/' />}
                        </Route>

                        <Route path='/login' >
                            {hasAuth() ? <Redirect to='/account' /> : <SignIn />}
                        </Route>

                        <Route path='/super'>
                            {isSuper() ? <Super /> : <Redirect to='/account' />}
                        </Route>

                        <Route path='/speedfolder-onboarding'>
                            <SpeedfolderOnboarding />
                        </Route>

                        <Route path='/granquartz-onboarding'>
                            <GranQuartzOnboarding />
                        </Route>

                        <Route path='/advertiser-onboarding'>
                            <AdvertiserOnboarding />
                        </Route>

                        <Route path='/app/marketing/*'>
                            <MarketingHashRedirect />
                        </Route>

                        <Route path='/app/speedfolder/jobs/:id'>
                            <SPEEDfolderJobRedirect />
                        </Route>

                        <Route path='/app/speedlabel/labels/:id'>
                            <SPEEDlabelLabelsRedirect />
                        </Route>

                        <Route path='/app/speedfolder/login'>
                            <ApplicationRedirect location={`${urlPrefix}/app/speedfolder`} />
                        </Route>

                        <Route path='/signup'>
                            {hasAuth() ? <Redirect to='/' /> : <Signup />}
                        </Route>

                        <Route path='/reset-password'>
                            {hasAuth() ? <Redirect to='/' /> : <PasswordReset />}
                        </Route>

                        <Route path='/invite'>
                            {hasAuth() ? <Redirect to='/account' /> : <Invite />}
                        </Route>

                        <Route path='/update-billing'>
                            <UpdateBilling />
                        </Route>

                        <Route path='/eula'>
                            {() => window.location.href = `${urlPrefix}${eula}`}
                        </Route>

                        <Route path='/sf-ad-tos'>
                            {() => window.location.href = `${urlPrefix}${sfAdTos}`}
                        </Route>

                        <Route path="*">
                            <NoMatch />
                        </Route>

                    </Switch>
                    <GlobalModal />
                </div>
            </ThemeProvider>
        </Auth0Provider >
    );
});

export default App;
