// import React, { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';

import { Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType, Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import SubscriptionsState from '../../state/subscriptions';
import ModalState from '../../state/modal';
import SpeedfolderState from '../../state/speedfolder';


import { hasAuth } from '../../utils/auth';
import AuthTabs from '../AuthTabs';

import { SPEEDFOLDER_APP_BASE, STRIPE_SPEEDFOLDER_PRODUCT_ID } from '../../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const LoadingMessage = <div><strong>Getting SPEEDfolder ready...</strong></div>;

const subscribeToSpeedfolder = async () => {
    try {
        document.body.style.pointerEvents = 'none'; // disable mouse events so users don't do anything weird
        ModalState.open(LoadingMessage);
        await SpeedfolderState.speedfolderCheckout(); // get everything ready
        window.location.href = SPEEDFOLDER_APP_BASE; // redirect
    } catch (err) {
        logger.error(err, 'Error subscribing to SPEEDfolder.'); // log error
        window.location.href = SPEEDFOLDER_APP_BASE; // redirect (SF probably won't work at this point but it's better than nothing)
        throw err;
    }
};

const FifthGearSignupForSpeedfolder = () => (
    <div>
        <h5><strong>Login</strong> or <strong>Enroll Company</strong> to get started with <em>SPEEDfolder</em> for free.</h5>
        <AuthTabs
            colorTheme={Colors.None}
            signUpSuccess={subscribeToSpeedfolder} // for SignUpForm | another callback because dillon has already built a bunch of stuff without using any async logic 🤦
            onLoginSuccess={subscribeToSpeedfolder} // for SignInForm
        />
    </div>
);

const SpeedfolderSignupButton = () => {
    const [hasSpeedfolder, setHasSpeedfolder] = useState(false);
    const isLoggedIn = hasAuth();

    useEffect(async () => {
        if (isLoggedIn) {
            await SubscriptionsState.fetchSubscriptions();
            setHasSpeedfolder(SubscriptionsState.subscriptions.findIndex((sub) => sub.program.external_product.id == STRIPE_SPEEDFOLDER_PRODUCT_ID) > -1);
        }
    }, []);

    return (
        <Button
            label={isLoggedIn && hasSpeedfolder ? 'Already Subscribed' : 'Get SPEEDfolder For Free'}
            type={isLoggedIn && hasSpeedfolder ? ButtonType.Grey : ButtonType.Add}
            colorTheme={Colors.Orange}
            disabled={(isLoggedIn && hasSpeedfolder)}
            onClick={() => isLoggedIn ? subscribeToSpeedfolder() : ModalState.open(<FifthGearSignupForSpeedfolder />)}
        />
    );
};

export default SpeedfolderSignupButton;